"use strict";

const hamburger = document.querySelector(".hamburger");
const navMenu = document.querySelector(".nav__menu");
const navMenuUlLiA = document.querySelectorAll(".nav__menu a");
const modal = document.querySelector('.modal');

const modalClose = document.querySelector('#close-modal');
const heroBtn = document.querySelector('.hero__btn');
const cardBtns = document.getElementsByClassName('temp-modal');

hamburger.addEventListener('click', () => {
    hamburger.classList.toggle('is-active')
    navMenu.classList.toggle('dis-flex')
})

navMenuUlLiA.forEach((navMenuUlLiA) => {
    navMenuUlLiA.addEventListener('click', () => {
        hamburger.classList.toggle('is-active')
        navMenu.classList.toggle('dis-flex')
    })
})

const modalToggle = () => {
    console.log("Clicked")
    modal.classList.toggle("dis-flex");
}
modalClose.addEventListener('click', modalToggle)
heroBtn.addEventListener('click', modalToggle)
Array.from(cardBtns).forEach(btn => {
    btn.addEventListener('click', modalToggle);
})